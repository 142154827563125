<script>
import BaseForm from './MemberBaseForm.vue';
import Organization from '../../services/organization';
const org = new Organization();
export default {
  extends: BaseForm,
  methods: {
    submit() {
      this.addUser();
    },
    cancel() {
      this.reset();
      this.$emit('cancel');
    },
    addUser() {
      if (this.formData.email === this.$store.state.user.email) {
        this.error = 'You can not add yourself as a member';
        return;
      }
      org.addMember(this.id, this.formData).then(()=>{
        this.$emit('success');
      }).catch(e=>{
        this.error = String(e)
      })
    }
  }
};
</script>

<style>
</style>
