import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.showAddUsers),callback:function ($$v) {_vm.showAddUsers=$$v},expression:"showAddUsers"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Add Member "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.hideDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('div',[_c('MemberAddForm',{attrs:{"id":_vm.id,"format-phone-number":_vm.formatPhone},on:{"success":function($event){return _vm.hideDialog()},"cancel":function($event){return _vm.hideDialog()}}})],1)])],1)],1),_c('div',[_c(VBtn,{staticClass:"text-none my-1",attrs:{"color":"primary"},on:{"click":function($event){_vm.showAddUsers = !_vm.showAddUsers}}},[_vm._v("Add new member "),_c(VIcon,{staticClass:"ml-1"},[_vm._v("mdi-account-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }