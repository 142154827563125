<template>
  <div v-if="data">
    <div v-if="usersComputed.length">
      <v-card outlined>
        <v-container>
          <base-data-table :headers="headers" :items="usersComputed" sort-by="lastLogin" sort-desc>
            <template v-slot:[`item.lastLogin`]="{ item }">
              {{ fromNow(item.lastLogin) }}
            </template>
            <template v-slot:[`item.lastLandActivityDate`]="{ item }">
              <base-prop-tooltip
                v-if="item.lastLandActivityDate"
                :tooltip="item.lastLandActivityDescription"
              >
                <span>
                  {{ fromNow(item.lastLandActivityDate) }}
                </span>
              </base-prop-tooltip>
            </template>
            <template v-slot:[`item.team`]="{ item }">
              <v-chip
                color="indigo"
                dark
                class="chip text-capitalize"
                :x-small="$vuetify.breakpoint.xs"
                :small="!$vuetify.breakpoint.xs"
                v-if="getTeam(data, item.email)"
                >{{ getTeam(data, item.email) }}</v-chip
              >
            </template>
          </base-data-table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import Organization from '../../services/organization';
import { getPermissions } from '../../utils/organization';
import { CMS } from '../../services/cms';
import { parseTeam, sort, teamIncludes } from '../../utils';
import User from '../../services/user';
import moment from 'moment';
import Activity from '../../services/activity';
import Gallery from '../../services/gallery';
const cms = new CMS();

const org = new Organization();
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    admin: {
      type: Boolean,
      default: false
    },
    organizationOwner: {}
  },
  data() {
    return {
      headers: [
        { text: 'Last name', value: 'lastName' },
        { text: 'First name', value: 'firstName' },
        { text: 'Login days', value: 'lastLogin' },
        { text: 'Last land activity', value: 'lastLandActivityDate' },
        { text: 'LTM Land activities', value: 'ltmLandActivities' },
        { text: 'LTM Photos', value: 'ltmPhotos' },
        { text: 'Teams', value: 'team' }
      ],
      data: null,
      unsubscribe: null,
      users: [],
      landStat: [],
      galleryStat: []
    };
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  created() {
    this.getData();
  },

  computed: {
    usersComputed() {
      if (!this.data || !this.data.members) {
        return [];
      }

      let users = [...this.data.members, { email: this.organizationOwner }];
      users = users.filter(u => !u.inactive);

      users = sort({
        items: users,
        generator: item => {
          return item.fullName || item.email;
        }
      });

      if (this.selectedTeam) {
        users = users.filter(user => {
          return teamIncludes([this.selectedTeam], user.team);
        });
      }

      users = users.map(user => {
        const data = {
          firstName: '',
          lastName: '',
          email: user.email,
          lastLogin: this.lastLogin(user.email),
          lastLandActivityDate: this.recentLandActivity(user.email).date,
          lastLandActivityDescription: this.recentLandActivity(user.email).description,
          ltmLandActivities: this.ltmLandActivities(user.email) || '',
          ltmPhotos: this.ltmPhotos(user.email) || '',
          permissions: this.getPermissions(user.email),
          name: user.fullName,
          team: user.team
        };

        data.firstName = this.user(user.email)?.data?.first_name;
        data.lastName = this.user(user.email)?.data?.last_name || user.email;
        return data;
      });

      return users;
    }
  },
  methods: {
    fromNow(date) {
      if (!date) return;
      return moment(date).fromNow();
    },
    landActivities(email) {
      const user = this.user(email);
      return this.landStat.filter(doc => {
        return doc.data.user === user?.id && doc.data.type !== 'metrics';
      });
    },
    ltmLandActivities(email) {
      const activities = this.landActivities(email);
      return [...activities].filter(activity => {
        const date = moment().subtract('12', 'months').toDate().getTime();
        return activity.data.createdAt.toDate().getTime() > date;
      }).length;
    },
    ltmPhotos(email) {
      const user = this.user(email);
      if (!user || !user?.id) return;

      const gallery = [...this.galleryStat].filter(post => {
        const date = moment().subtract('12', 'months').toDate().getTime();
        return post.data.user === user?.id && post.data.createdAt.toDate().getTime() > date;
      });
      let photos = 0;
      gallery.forEach(post => {
        photos += post?.data?.images?.length || 0;
      });
      return photos;
    },
    recentLandActivity(email) {
      const activity = this.landActivities(email)[0];
      const activityParser = new Activity(activity?.data);
      return {
        description: activityParser.format(),
        status: !!activityParser.format(),
        date: activity?.data?.createdAt?.toDate().getTime()
      };
    },
    user(email) {
      const user = this.users.filter(u => {
        return String(u?.data?.email).toLowerCase() === String(email).toLowerCase();
      });
      if (!user[0]) return;
      return user[0];
    },
    lastLogin(email) {
      const user = this.user(email);
      const date = user?.data?.last_login;
      if (!date) return;
      return date?.toDate()?.getTime();
    },
    getPermissions(email) {
      if (!this.data) {
        return [];
      }
      return getPermissions({ email, data: this.data });
    },
    parseTeam,
    getTeam(data, user) {
      if (data.team && data.team[user]) {
        return data.team[user];
      }
      return null;
    },
    getData() {
      this.unsubscribe = org.getPermissions(this.id, res => {
        if (res.exists) {
          this.data = res.data();
          this.fetchMembers();
        }
      });
    },
    async fetchMembers() {
      let users = [...this.usersComputed, { email: this.organizationOwner }].map(i => i.email);
      users = users.map(email => {
        const u = new User();
        return u.get({ email });
      });
      users = await Promise.all(users.map(p => p.catch(e => e)));
      users = users.filter(i => !(i instanceof Error)).filter(i => !!i);
      this.users = [...users];
      this.fetchLandStat();
      this.fetchGalleryStat();
    },
    async fetchLandStat() {
      const activity = new Activity();
      activity.list({ organization: this.id }).then(res => {
        const data = sort({
          items: res,
          desc: true,
          generator: item => {
            return item.data.createdAt.toDate().getTime();
          }
        });
        this.landStat = data;
      });
    },
    async fetchGalleryStat() {
      const gallery = new Gallery();
      gallery.list({ organization: this.id }).then(res => {
        const data = sort({
          items: res,
          desc: true,
          generator: item => {
            return item.data.createdAt.toDate().getTime();
          }
        });
        this.galleryStat = data;
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/design/_colors.scss';
.chip-container {
  max-width: 350px;
  @media (max-width: 600px) {
    max-width: 150px;
  }
}
// @media (max-width: 600px) {
.chip {
  margin-bottom: 1px;
  margin-top: 1px;
}
// }
.hidden {
  visibility: hidden;
}
</style>
