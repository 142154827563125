import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',[(_vm.usersComputed.length)?_c('div',[_c(VCard,{attrs:{"outlined":""}},[_c(VContainer,[_c('base-data-table',{attrs:{"headers":_vm.headers,"items":_vm.usersComputed,"sort-by":"lastLogin","sort-desc":""},scopedSlots:_vm._u([{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fromNow(item.lastLogin))+" ")]}},{key:"item.lastLandActivityDate",fn:function(ref){
var item = ref.item;
return [(item.lastLandActivityDate)?_c('base-prop-tooltip',{attrs:{"tooltip":item.lastLandActivityDescription}},[_c('span',[_vm._v(" "+_vm._s(_vm.fromNow(item.lastLandActivityDate))+" ")])]):_vm._e()]}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [(_vm.getTeam(_vm.data, item.email))?_c(VChip,{staticClass:"chip text-capitalize",attrs:{"color":"indigo","dark":"","x-small":_vm.$vuetify.breakpoint.xs,"small":!_vm.$vuetify.breakpoint.xs}},[_vm._v(_vm._s(_vm.getTeam(_vm.data, item.email)))]):_vm._e()]}}],null,true)})],1)],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }