<template>
  <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
    <v-row>
      <v-col cols="3">
        <v-text-field
          :disabled="disabled"
          outlined
          label="Key"
          v-model="formData.key"
          :rules="rules.requiredRule"
        ></v-text-field>
      </v-col>
      <v-col cols="9">
        <v-text-field
          :disabled="disabled"
          outlined
          label="Name"
          v-model="formData.name"
          :rules="rules.requiredRule"
        ></v-text-field>
      </v-col>
    </v-row>
    <AlertMessage :success="success" :error="error" />
    <BaseFormAction
      :x-large="false"
      large
      :submit-label="submitLabel"
      :vm="this"
      :loading="loading"
    />
  </v-form>
</template>

<script>
import ruleMixin from '../../mixins/rules';
import AlertMessage from '../../components/Core/AlertMessage.vue';
export default {
  mixins: [ruleMixin],
  components: {
    AlertMessage
  },
  props: {
    submitLabel: {
      type: String,
      default: 'Submit'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {},
      valid: true,
      loading: false,
      success: '',
      error: ''
    };
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    submit() {},
    cancel() {},
    onSubmit() {
      this.success = '';
      this.error = '';
      this.$refs.form.validate();
      if (this.valid) {
        this.submit();
      }
    },
    onCancel() {
      this.cancel();
    }
  }
};
</script>

<style>
</style>
