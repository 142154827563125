<template>
  <div>
    <v-dialog width="500" v-model="showAddUsers">
      <v-card class="mx-auto">
        <v-card-title>
          Add Member
          <v-spacer></v-spacer>
          <v-btn icon @click="hideDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <div>
            <MemberAddForm
              :id="id"
              @success="hideDialog()"
              @cancel="hideDialog()"
              :format-phone-number="formatPhone"
            />
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <div>
      <v-btn color="primary" class="text-none my-1" @click="showAddUsers = !showAddUsers"
        >Add new member <v-icon class="ml-1">mdi-account-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Organization from '../../services/organization';
import MemberAddForm from './MemberAddForm.vue';
export default {
  components: {
    MemberAddForm
  },
  props: {
    id: {
      type: String,
      required: true
    },
    setting: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showAddUsers: false
    };
  },
  computed: {
    formatPhone() {
      return this.setting?.formatPhone;
    }
  },
  methods: {
    hideDialog() {
      this.showAddUsers = false;
    }
  }
};
</script>

<style>
</style>
