<template>
  <div v-if="data">
    <v-dialog width="500" v-model="edit">
      <v-card class="mx-auto">
        <v-card-title>
          <v-spacer></v-spacer>
          Edit {{  isMemberInactive ? ' Inactive ' : '' }} Member
          <v-spacer></v-spacer>
          <v-btn icon @click="edit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <div>
            <MemberEditForm
              :OrganizationPermissionDescription="OrganizationPermissionDescription"
              :key="editEmail"
              :id="id"
              :user="editEmail"
              @success="onUpdate()"
              @cancel="onUpdate()"
              submit-label="Update"
              allow-remove
              disabled
              :inactive.sync="isMemberInactive"
              :format-phone-number="formatPhone"
            />
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <div v-if="usersComputed.length">
      <div class="d-flex flex-wrap">
        <h2 class="text-h6">{{ usersComputed.length }} Members</h2>
        <v-btn
          text
          class="ml-2 text-capitalize v-btn--active"
          color="info lighten-2"
          v-if="selectedTeam || admin"
          @click="emailMembers(usersComputed)"
        >
          <v-icon color="primary">mdi-email</v-icon>
          <span class="primary--text"> Email </span>
          <span v-if="selectedTeam" class="primary--text">
            member{{ usersComputed.length > 1 ? 's' : '' }} of team {{ selectedTeam }}</span
          >
          <span class="primary--text" v-else> all members </span>
        </v-btn>
        <!-- <v-spacer></v-spacer> -->
      </div>
      <div v-if="teams().length" class="my-2">
        <div>
          <v-btn
            v-for="team in teams()"
            :key="team"
            small
            @click="selectedTeam = team"
            class="mx-1 cursor-pointer"
            color="primary"
            :outlined="team !== selectedTeam"
          >
            {{ team }}
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="grey"
            :class="{ hidden: !selectedTeam }"
            @click="selectedTeam = ''"
            text
            >Clear <v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <small> Click on above team to select </small>
      </div>
      <MemberList :members="usersComputed" :data="data" @click="showEdit" :editable="editable" />
      <small v-show="editable">Click on above email to edit member</small>
    </div>
    <div v-else>
      <v-alert type="info" text> No members </v-alert>
    </div>
    <div class="d-flex flex-wrap justify-space-around">
      <v-btn
        v-if="inactiveUsers.length"
        @click="dialogs.inactive = !dialogs.inactive"
        color="secondary"
        text
        class="v-btn--active text-capitalize my-1"
        >Inactive members
        <v-icon>{{ dialogs.inactive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="v-btn--active text-none my-1 mr-1"
        color="info lighten-2"
        @click="exportMembers()"
        text
        v-if="admin"
      >
        <span class="primary--text"> Export CSV </span>
      </v-btn>
      <slot name="footer"></slot>
    </div>
    <div>
      <v-slide-y-transition>
        <div v-if="dialogs.inactive">
          <MemberList
            :members="inactiveUsers"
            :data="data"
            @click="showEdit"
            :editable="editable"
          />
        </div>
      </v-slide-y-transition>
    </div>
  </div>
</template>

<script>
import Organization from '../../services/organization';
import MemberEditForm from './MemberEditForm.vue';
import { getPermissions } from '../../utils/organization';
import { CMS } from '../../services/cms';
import Phone from '../../services/phone';
import { downloadFile, parseTeam, teamIncludes } from '../../utils';
import _ from 'lodash';
const cms = new CMS();

const org = new Organization();
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    admin: {
      type: Boolean,
      default: false
    },
    setting: {
      type: Object,
      default: null
    }
  },
  components: {
    MemberEditForm,
    // MemberDetail: () => import('./MemberDetail.vue'),
    MemberList: () => import('./MemberList.vue')
  },
  data() {
    return {
      isMemberInactive: false,
      dialogs: {
        inactive: false
      },
      data: null,
      unsubscribe: null,
      edit: false,
      editEmail: '',
      OrganizationPermissionDescription: 'OrganizationPermissionDescription',
      selectedTeam: ''
    };
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  created() {
    this.getData();
    this.getPermissionDescription();
  },

  filters: {
    fmtCamelCase(str) {
      return _.startCase(str);
    }
  },
  computed: {
    formatPhone() {
      return this.setting?.formatPhone;
    },
    myEmail() {
      return this.$store.state.user.email;
    },
    usersComputed() {
      let members = this.data?.members || [];
      members = members.filter(m => !m.inactive);

      if (this.selectedTeam) {
        members = members.filter(m => {
          const teams = (m.team || '').split(',').map(i => i.trim());
          return teams.includes(this.selectedTeam);
        });
      }

      return members;
    },
    inactiveUsers() {
      let members = this.data?.members || [];
      members = members.filter(m => m.inactive);
      return members;
    },
    loggedInUserIndex() {
      const index = this.usersComputed.findIndex(i => i === this.myEmail);
      return index;
    }
  },
  methods: {
    exportMembers() {
      const fields = [
        { label: 'Name', value: 'fullName' },
        { label: 'Email', value: 'email' },
        { label: 'Member since', value: 'memberSince' },
        { label: 'Mobile Device/Browser', value: 'deviceMobile' },
        { label: 'Desktop Device/Browser', value: 'deviceDesktop' },
        { label: 'Wireless Carrier', value: 'wirelessCarrier' },
        { label: 'Teams', value: 'team' }
      ];
      let data = [...this.usersComputed];

      const addLabel = (labels, label) => {
        const res = labels;
        const index = labels.findIndex(i => i.value === label.value);
        if (index === -1) {
          res.push({ ...label, label: _.startCase(label.label) });
        }
        return labels;
      };

      const formatAddress = (street, city, state, zip) => {
        let address = ``;
        address += street;
        if (address) {
          address += '\n';
        }
        if (city) {
          address += `${city}, `;
        }
        if (state) {
          address += `${state} `;
        }
        if (zip) {
          address += zip;
        }
        return address;
      };

      let phoneLabels = [];
      let otherLabels = [];

      data = data.map(member => {
        const memberData = { ...member };

        if (Array.isArray(member.phone)) {
          member.phone.forEach(phone => {
            const key = _.camelCase(`phone ${phone.label}`);
            phoneLabels = addLabel(phoneLabels, { label: phone.label, value: key });
            const p = new Phone({ phone: phone.number });

            if (this.setting?.formatPhone) {
              memberData[key] = p.format();
            } else {
              memberData[key] = phone.number;
            }
          });
        }

        if (Array.isArray(member.address)) {
          member.address.forEach(address => {
            const key = _.camelCase(`address ${address.label}`);
            otherLabels = addLabel(otherLabels, { label: `${address.label} address`, value: key });
            memberData[key] = formatAddress(
              address?.street,
              address?.city,
              address?.state,
              address?.zip
            );
            if (address.gps) {
              const gpsLabel = `${address.label} gps`;
              const gpsKey = _.camelCase(gpsLabel);
              otherLabels = addLabel(otherLabels, { label: gpsLabel, value: gpsKey });
              memberData[gpsKey] = address.gps;
            }
          });
        }

        Object.keys(memberData).forEach(k => {
          if (!memberData[k]) {
            memberData[k] = '';
          }
        });

        return memberData;
      });

      fields.splice(2, 0, ...[...phoneLabels, ...otherLabels]);

      const { Parser } = require('json2csv');

      const opts = { fields };
      const parser = new Parser(opts);
      const csv = parser.parse(data);
      downloadFile(`data:text/csv;charset=utf-8,${encodeURI(csv)}`, 'members.csv');
    },
    showEdit(member) {
      this.editEmail = member.email;
      this.edit = true;
    },
    migrate() {
      const o = new Organization();
      o.migrate(this.id);
    },
    parseTeam,
    teams() {
      const user = this.$store.state.user.email;

      if (!this.admin) {
        const team = this.data.members.filter(i => {
          return String(i.email).toLowerCase().trim() === String(user).toLowerCase().trim();
        })[0];
        if (!team) {
          return [];
        }
        return parseTeam(team.team);
      }

      const teams = [];

      this.data.members.forEach(member => {
        teams.push(...parseTeam(member.team));
      });

      return [...new Set(teams)].sort();
    },
    emailMembers(emails = []) {
      const emailsStr = emails.map(i => i.email).join(',');
      window.open('mailto:' + emailsStr);
    },
    getPermissionDescription() {
      cms
        .get({
          key: 'OrganizationPermissionDescription',
          type: 'html'
        })
        .then(res => {
          if (res.size) {
            this.OrganizationPermissionDescription = res.docs[0].data();
          }
        });
    },
    getTeam(data, user) {
      if (data.team && data.team[user]) {
        return data.team[user];
      }
      return null;
    },
    onUpdate() {
      this.editEmail = '';
      this.edit = false;
    },
    editPermissions(email) {
      if (!this.editable) {
        return;
      }
      this.editEmail = email;
      this.edit = true;
    },
    getPermissions(email) {
      if (!this.data) {
        return [];
      }
      return getPermissions({ email, data: this.data });
    },
    getData() {
      this.unsubscribe = org.getPermissions(this.id, res => {
        if (res.exists) {
          this.data = res.data();
          this.$emit('update:members', this.data);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.chip-container {
  max-width: 350px;
  @media (max-width: 600px) {
    max-width: 150px;
  }
}
// @media (max-width: 600px) {
.chip {
  margin-bottom: 1px;
  margin-top: 1px;
}
// }
.hidden {
  visibility: hidden;
}
</style>
