<script>
import BaseForm from './BaseForm.vue';
import Organization from '../../services/organization';
import { fullName } from '../../utils';
const org = new Organization();
export default {
  extends: BaseForm,
  props: {
    id: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      default: 'Update'
    }
  },
  watch: {
    id: {
      handler() {
        this.getData();
      },
      immediate: true
    }
  },
  methods: {
    getData() {
      org.retrieve(this.id).then(res => {
        this.formData = { ...res.data() };
      });
    },
    submit() {
      this.loading = true;

      const userFullName = fullName(this.$store.state.user.profile);
      const userEmail = this.$store.state.user.profile.email;

      const data = {
        ...this.formData,
        userFullName,
        userEmail
      };

      org
        .update(this.id, data)
        .then(() => {
          this.$emit('success');
        })
        .catch(error => {
          this.error = error.message;
          setTimeout(() => {
            this.error = '';
          }, 5000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
