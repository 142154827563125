<template>
  <v-card width="650" class="mx-auto mt-5" color="primary-light" outlined v-if="!loading">
    <v-card-title>
      Monitoring Frequency
      <v-spacer></v-spacer>
      <v-btn
        v-if="!edit && editable"
        color="primary"
        @click="edit = true"
        outlined
        class="text-capitalize"
        small
      >
        Edit <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <slot name="card-text-top"></slot>
      <v-fade-transition>
        <v-alert type="error" text v-if="error">
          {{ error }}
        </v-alert>
      </v-fade-transition>
      <v-fade-transition>
        <v-alert type="success" text v-if="success">
          {{ success }}
        </v-alert>
      </v-fade-transition>
      <v-form ref="form" v-model="valid" v-if="edit">
        <v-row v-for="land in landTypes" :key="land.value" align="center" dense class="text-center">
          <v-col>
            {{ land.text }}
          </v-col>
          <v-col v-for="team in teams" :key="team">
            <v-text-field
              :rules="requiredRule"
              :label="team"
              v-model="formData[`${land.shortName}_${team}`]"
            ></v-text-field>
          </v-col>
        </v-row>
        <div>
          <BaseFormAction
            submit-label="Update"
            :x-large="false"
            @click="update"
            @cancel="cancel"
            :loading="btnLoading"
          />
        </div>
      </v-form>
      <div v-else>
        <v-simple-table class="table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Class</th>
                <th class="text-left">A</th>
                <th class="text-left">B</th>
                <th class="text-left">C</th>
                <th class="text-left">D</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="land in landTypes" :key="land.text">
                <td>
                  {{ land.text }}
                </td>
                <td v-for="team in teams" :key="team">
                  {{ formData[`${land.shortName}_${team}`] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <slot name="card-text-bottom"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import Land from '../../services/land';
import Organization from '../../services/organization';
const org = new Organization();
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      valid: true,
      requiredRule: [v => !!v || 'Monitoring frequency is required'],
      formData: {
        FS_A: 12,
        FS_B: 12,
        FS_C: 12,
        FS_D: 12,

        CR_A: 12,
        CR_B: 12,
        CR_C: 12,
        CR_D: 12,

        OT_A: 12,
        OT_B: 12,
        OT_C: 12,
        OT_D: 12
      },

      landTypes: Land.landTypes(),
      teams: ['A', 'B', 'C', 'D'],
      loading: true,
      success: '',
      error: '',
      btnLoading: false,
      edit: false
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    cancel() {
      this.fetch().then(() => {
        this.edit = false;
      });
    },
    async fetch() {
      await org
        .getMonitoringFrequency(this.id)
        .then(({ data }) => {
          this.formData = data;
        })
        .catch(() => {
          this.update(false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async update(hide = true) {
      this.success = '';
      this.error = '';
      this.btnLoading = true;
      try {
        await org.updateMonitoringFrequency(this.id, this.formData);
        if (hide) {
          this.success = 'Monitoring frequency updated';
          this.edit = false;
        }
      } catch (error) {
        if (error && error.message) {
          this.error = error.message;
        }
      }
      setTimeout(() => {
        this.success = '';
        this.error = '';
      }, 4000);
      this.btnLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../design/_colors.scss';
tr {
  background-color: $color-primary-light;
}
</style>
