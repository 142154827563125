<template>
  <v-main>
    <div class="ma-5 text-center" v-if="!detail">
      <BaseProgressCircular />
    </div>
    <v-container v-else>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card max-width="650" color="primary-light" class="mx-auto" outlined v-if="edit">
        <v-card-title> Edit Organization </v-card-title>
        <v-card-text>
          <EditOrganization
            :id="$route.params.id"
            @success="
              edit = false;
              getData();
            "
            @cancel="edit = false"
          />
        </v-card-text>
      </v-card>

      <v-card width="650" color="primary-light" class="mx-auto" outlined v-else>
        <v-card-title>
          {{ detail.name }}
          <v-spacer></v-spacer>
          <v-btn
            v-if="detail.user === $store.state.user.uid && $store.getters.isAdmin"
            color="primary"
            outlined
            small
            class="text-capitalize"
            @click="edit = true"
            >Edit<v-icon>mdi-pencil</v-icon></v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          {{ detail.key }}
          <div class="mt-3">
            <strong v-if="detail.userEmail">
              Admin:
              <span v-if="detail.userFullName">
                {{ detail.userFullName }}
                <br />
              </span>
              <a
                :href="`mailto:${detail.userEmail}`"
                class="decoration-none"
                :style="`margin-left: ${detail.userFullName ? '50px' : ''}`"
                target="_blank"
              >
                {{ detail.userEmail }}
              </a>
            </strong>
          </div>
        </v-card-subtitle>
      </v-card>
      <!-- <v-card width="650" color="primary-light" class="mx-auto mt-5 mb-5" outlined> -->
      <MonitoringDashboard
        v-if="detail.user === $store.state.user.uid || $store.getters.isAdmin"
        :members="members"
        :id="$route.params.id"
        width="650"
        color="primary-light"
        class="mx-auto mt-5 mb-5"
        outlined
      />
      <!-- </v-card> -->
      <v-card width="650" color="primary-light" class="mx-auto" outlined>
        <v-card-text>
          <OrganizationMembers
            :editable="
              detail.user === $store.state.user.uid ||
              myPermissions.includes('addMember') ||
              $store.getters.isAdmin
            "
            :setting="setting"
            :members.sync="members"
            :admin="detail.user === $store.state.user.uid || $store.getters.isAdmin"
            :id="$route.params.id"
            class="mt-5"
            refs="members"
          >
            <template #footer>
              <AddMembers
                :id="$route.params.id"
                v-if="detail.user === $store.state.user.uid || myPermissions.includes('addMember')"
                :setting="setting"
              />
            </template>
          </OrganizationMembers>
        </v-card-text>
      </v-card>

      <v-card
        class="mx-auto mt-5"
        flat
        v-if="$store.getters.isAdmin"
        :max-width="memberStat ? '' : 650"
      >
        <v-card-title>
          <v-btn
            color="primary"
            class="text-capitalize"
            :outlined="!memberStat"
            @click="memberStat = !memberStat"
            >Data table <v-icon>{{ memberStat ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-slide-y-transition>
            <OrganizationMembersStat
              :organization-owner="detail.userEmail"
              v-show="memberStat"
              :members.sync="members"
              :id="$route.params.id"
              class="mt-5"
            />
          </v-slide-y-transition>
        </v-card-text>
      </v-card>

      <MonitoringFrequency
        :id="$route.params.id"
        :editable="detail.user === $store.state.user.uid || $store.getters.isAdmin"
      >
        <template #card-text-bottom>
          <CalculateMdd
            v-if="features && features.land && $store.getters.isAdmin"
            :id="$route.params.id"
          />
        </template>
      </MonitoringFrequency>

      <v-card width="650" color="primary-light" class="mx-auto mt-5" outlined>
        <v-card-title>Features</v-card-title>
        <v-card-text>
          <OrganizationFeatures
            @input="setFeatures"
            :editable="detail.user === $store.state.user.uid || $store.getters.isAdmin"
            :id="$route.params.id"
            class="mt-5"
          />
        </v-card-text>
      </v-card>

      <v-card width="650" color="primary-light" class="mx-auto" outlined>
        <v-card-title>Settings</v-card-title>
        <v-card-text>
          <OrganizationSetting
            :id="$route.params.id"
            class="mt-5"
            :editable="detail.user === $store.state.user.uid || $store.getters.isAdmin"
          />
        </v-card-text>
      </v-card>

      <OrganizationLandPreserves
        :editable="detail.user === $store.state.user.uid || $store.getters.isAdmin"
        :id="$route.params.id"
        class="mt-5"
      />
    </v-container>
  </v-main>
</template>

<script>
import EditOrganization from '../../components/Organization/Edit.vue';
import OrganizationMembers from '../../components/Organization/Members.vue';
import OrganizationMembersStat from '../../components/Organization/MembersStat.vue';
import AddMembers from '../../components/Organization/AddMembers.vue';
import Organization from '../../services/organization';
import { getPermissions } from '../../utils/organization';
import userMixin from '../../mixins/user';
import { mapState } from 'vuex';
import MonitoringFrequency from '../../components/Organization/MonitoringFrequency.vue';
const org = new Organization();
export default {
  mixins: [userMixin],
  components: {
    EditOrganization,
    AddMembers,
    OrganizationMembers,
    OrganizationFeatures: () => import('../../components/Organization/Features.vue'),
    MonitoringFrequency: () => import('../../components/Organization/MonitoringFrequency.vue'),
    MonitoringFrequency,
    CalculateMdd: () => import('../../components/Organization/CalculateMdd.vue'),
    OrganizationSetting: () => import('../../components/Organization/Setting.vue'),
    OrganizationLandPreserves: () => import('../../components/Organization/LandPreserves.vue'),
    MonitoringDashboard: () => import('../../components/Organization/MonitoringDashboard.vue'),
    OrganizationMembersStat
  },
  data() {
    return {
      detail: null,
      edit: false,
      breadcrumbs: [],
      myPermissions: [],
      features: null,
      members: {},
      memberStat: false,
      setting: null,
      unsubscribeSetting: null
    };
  },
  computed: {
    ...mapState(['permissions'])
  },
  created() {
    this.getData();
    this.getUserPermissions();
    this.getSetting();
  },
  beforeDestroy() {
    if (this.unsubscribeSetting) {
      this.unsubscribeSetting();
    }
  },
  methods: {
    getSetting() {
      org
        .getSetting(this.$route.params.id, data => {
          this.setting = data;
        })
        .then(snap => {
          this.unsubscribeSetting = snap;
        });
    },
    setFeatures(features) {
      this.features = features;
    },
    async getUserPermissions() {
      const user = await this.currentUser();
      const permissions = await org.getPermissions(this.$route.params.id);
      const myPermissions = getPermissions({
        email: user.email,
        data: permissions.data()
      });
      if (myPermissions) {
        this.myPermissions = myPermissions;
      }
    },
    generateBreadcrumb() {
      this.breadcrumbs = [
        {
          text: 'Organizations',
          to: '/organizations',
          exact: true
        },
        {
          text: this.detail.name,
          to: '',
          exact: true,
          disabled: true
        }
      ];
    },
    onError() {
      this.$router.push('/organizations');
    },
    getData() {
      org
        .retrieve(this.$route.params.id)
        .then(res => {
          if (res.exists) {
            this.detail = res.data();
            this.generateBreadcrumb();
          } else {
            this.onError();
          }
        })
        .catch(() => {
          this.$router.push('/organizations');
        });
    }
  }
};
</script>

<style>
</style>
